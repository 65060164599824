import 'flatpickr';
import 'flatpickr/dist/l10n/fr';

document.addEventListener('DOMContentLoaded', () => {
  initDatePicker();
});

function initDatePicker() {
  // Cas spécifique pour flitre commande:
  initDatePickerWithoutTime();
  initDatePickerWithTime();
}

export function initDatePickerWithoutTime() {
  $('.flatpickr-date').flatpickr({
    locale: 'fr',
    altInput: true,
    altFormat: 'j F Y',
  });
}

export function initDatePickerWithTime() {
  $('.flatpickr-dateTime').flatpickr({
    dateFormat: 'Y-m-d H:i:S',
    locale: 'fr',
    altInput: true,
    altFormat: 'j F Y H:i',
    enableTime: true,
    position: 'above',
  });
}
