document.addEventListener('DOMContentLoaded', () => {
  const languageFr = {
    processing: 'Traitement en cours...',
    search: 'Rechercher&nbsp;:',
    lengthMenu: 'Afficher _MENU_ &eacute;l&eacute;ments',
    info: "Affichage de l'&eacute;lement _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
    infoEmpty: "Affichage de l'&eacute;lement 0 &agrave; 0 sur 0 &eacute;l&eacute;ments",
    infoFiltered: '(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)',
    infoPostFix: '',
    loadingRecords: 'Chargement en cours...',
    zeroRecords: 'Aucun &eacute;l&eacute;ment &agrave; afficher',
    emptyTable: 'Aucune donnée disponible dans le tableau',
    paginate: {
      first: 'Premier',
      previous: 'Pr&eacute;c&eacute;dent',
      next: 'Suivant',
      last: 'Dernier',
    },
    aria: {
      sortAscending: ': activer pour trier la colonne par ordre croissant',
      sortDescending: ': activer pour trier la colonne par ordre décroissant',
    },
  };

  if (document.getElementById('max')) {
    $.fn.dataTable.ext.search.push((settings, data) => {
      const min = $('#min').val() * 1;
      const max = $('#max').val() * 1;
      const prix = parseFloat(data[12].replace(/\s/g, '')) || 0;
      if (
        (min === 0 && max === 0) ||
        (min === 0 && prix <= max) ||
        (min <= prix && max === 0) ||
        (min <= prix && prix <= max)
      ) {
        return true;
      }
      return false;
    });
  }

  const table = $('#lots').DataTable({
    dom: '<"top"i flp>rt',
    paging: false,
    language: languageFr,
    columnDefs: [],
    initComplete() {
      const { hash } = window.location;
      if (hash) {
        $([document.documentElement, document.body]).animate(
          {
            scrollTop: $(`${hash}`).offset().top,
          },
          10
        );
      }
    },
  });

  if (document.getElementById('max')) {
    $('#min, #max').keyup(() => {
      table.draw();
    });

    $('#statuts').change(function () {
      table
        .column(0)
        .search(`(^${$(this).val()}$)`, true, false)
        .draw();
    });
  }
});
