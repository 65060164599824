import $ from 'jquery';

document.addEventListener('DOMContentLoaded', () => {
  const buttonForm = $('.modalButton');
  buttonForm.click(function () {
    $('#lien-liberation').attr('data-href', $(this).attr('data-href'));

    $('#date').addClass('d-none');
    $('#lot_option_lot').val($(this).attr('data-idLot'));
    $('#buttonFormLot').attr('disabled', true);
    $('#buttonFormLot').prepend('<div id="spinner" class="spinner-border spinner-border-sm text-white mr-1"></div>');
    $.ajax({
      url: `/ajax/ajax_get_last_option/${$(this).attr('data-idLot')}`,
      success(html) {
        $('#buttonFormLot').attr('disabled', false);
        $('#spinner').remove();
        $('#lot_option_prenomClient').replaceWith($(html).find('#lot_option_prenomClient'));
        $('#lot_option_nomClient').replaceWith($(html).find('#lot_option_nomClient'));
        $('#lot_option_statutLotOption').replaceWith($(html).find('#lot_option_statutLotOption'));
        $('#modification_option').replaceWith($(html).find('#modification_option'));
        if ($('#lot_option_nomClient').val() || $('#lot_option_prenomClient').val()) {
          // $('#clientInfoPartenaire').removeClass('d-none');
        } else {
          $('#clientInfo').removeClass('d-none');
          if ($('#lot_option_statutLotOption').val() === 2) {
            // $('#clientInfoPartenaire').removeClass('d-none');
          }
        }
        $('#clientInfoPartenaire').removeClass('d-none');
        $('#lot_option_note').replaceWith($(html).find('#lot_option_note'));
        $('#lot_option_partenaire').replaceWith($(html).find('#lot_option_partenaire'));
        $('#lot_option_client').replaceWith($(html).find('#lot_option_client'));
        if ($('#lot_option_statutLotOption').val() === 2) {
          $('#dateFinoption').removeClass('d-none');
        }
      },
    });
  });

  const buttonFormAnnulation = $('.modalButtonAnnulation');
  buttonFormAnnulation.click(function () {
    $('#buttonFormLotAnnulation').attr('href', $(this).attr('data-href'));
  });
});
