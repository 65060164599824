const adminNs = {
  initDraggableEntityRows() {
    let dragSrcEl = null; // the object being drug
    let endPosition = null; // the index of the row element being dropped on (0 through whatever)
    let parent; // the parent element of the dragged item
    let entityId; // the id (key) of the entity
    let path;
    let lot;
    // eslint-disable-next-line no-unused-vars
    let startPosition = null; // the index of the row element (0 through whatever)

    function handleDragStart(e) {
      dragSrcEl = this;
      entityId = $(this).attr('rel');
      path = $(this).attr('data-path');
      lot = $(this).attr('data-lot');
      dragSrcEl.style.opacity = '0.4';
      parent = dragSrcEl.parentNode;
      startPosition = Array.prototype.indexOf.call(parent.children, dragSrcEl);
      e.dataTransfer.effectAllowed = 'move';
      e.dataTransfer.setData('text/html', this.innerHTML);
    }
    function handleDragOver(e) {
      // console.log('drag over: '+ e.target);
      if (e.preventDefault) {
        e.preventDefault(); // Necessary. Allows us to drop.
      }
      e.dataTransfer.dropEffect = 'move'; // See the section on the DataTransfer object.

      return false;
    }
    function handleDragEnter() {
      this.classList.add('over');
    }
    function handleDragLeave() {
      this.classList.remove('over'); // this / e.target is previous target element.
    }
    function handleDrop(e) {
      if (e.stopPropagation) {
        e.stopPropagation(); // stops the browser from redirecting.
      }

      // Don't do anything if dropping the same column we're dragging.
      if (dragSrcEl !== this) {
        endPosition = Array.prototype.indexOf.call(parent.children, this);
        // Set the source column's HTML to the HTML of the column we dropped on.
        dragSrcEl.innerHTML = this.innerHTML;
        this.innerHTML = e.dataTransfer.getData('text/html');

        // do the ajax call to update the database
        $.ajax({
          url: `${path}?idEntity=${entityId}&position=${endPosition}`,
        })
          .done((res) => {
            if (lot) {
              $(`#DoubleOptionTable_${lot}`).replaceWith($(res).find(`#DoubleOptionTable_${lot}`));
            } else {
              $('table.sortable tbody').replaceWith($(res).find('table.sortable tbody'));
            }
          })
          .fail(() => {
            // eslint-disable-next-line no-alert
            alert('An error occurred while sorting. Please refresh the page and try again.');
          })
          .always(() => {
            adminNs.initDraggableEntityRows();
          });
      }

      return false;
    }
    function handleDragEnd() {
      this.style.opacity = '1'; // this / e.target is the source node.
      [].forEach.call(rows, (row) => {
        row.classList.remove('over');
      });
    }

    let rows = document.querySelectorAll('table.sortable > tbody tr');
    [].forEach.call(rows, (row) => {
      row.addEventListener('dragstart', handleDragStart, false);
      row.addEventListener('dragenter', handleDragEnter, false);
      row.addEventListener('dragover', handleDragOver, false);
      row.addEventListener('dragleave', handleDragLeave, false);
      row.addEventListener('drop', handleDrop, false);
      row.addEventListener('dragend', handleDragEnd, false);
    });
  },

  /**
   * Primary Admin initialization method.
   * @returns {boolean}
   */
  init() {
    this.initDraggableEntityRows();

    return true;
  },
};

$(() => {
  adminNs.init();
});
