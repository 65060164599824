import Quill from 'quill';

document.addEventListener('DOMContentLoaded', () => {
  const containers = document.querySelectorAll('.quill');

  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    ['blockquote', 'code-block'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
    [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ align: [] }],
  ];
  containers.forEach((element) => {
    const isDisabled = element.classList.contains('disabled');
    const quill = new Quill(element, {
      theme: element.dataset.theme,
      placeholder: isDisabled ? '' : element.dataset.placeholder,
      modules: {
        toolbar: isDisabled ? false : toolbarOptions,
      },
    });

    const textareaId = element.dataset.id;

    quill.on('text-change', () => {
      document.querySelector(`#${textareaId}`).value = quill.root.innerHTML.replace(/"/g, "'");
    });

    if (isDisabled) {
      quill.disable();
    }
  });
});
