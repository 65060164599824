const options = {
  locale: {
    currentlySelected: 'Actuellement sélectionné',
    emptyTitle: 'Sélectionner et commencer à taper',
    errorText: 'Impossible de récupérer les résultats',
    searchPlaceholder: 'Rechercher...',
    statusInitialized: 'Commencer à taper une recherche',
    statusNoResults: 'Aucun résultat',
    statusSearching: 'Recherche en cours...',
    statusTooShort: 'Entrez plus de caractères',
  },
  ajax: {
    url: '/ajax/ajax_get_communes',
    type: 'POST',
    dataType: 'json',
    data() {
      const params = {
        search: '{{{q}}}',
        idDpt: $('#programme_commune').val(),
      };
      return params;
    },
  },
  preprocessData(data) {
    const communes = [];
    // eslint-disable-next-line no-prototype-builtins
    if (data.hasOwnProperty('results')) {
      const len = data.results.length;
      for (let i = 0; i < len; i++) {
        const curr = data.results[i];
        communes.push({
          value: curr.codeInsee,
          text: `${curr.codePostal} - ${curr.commune}`,
        });
      }
    }
    return communes;
  },
  preserveSelected: true,
};

$('#programme_commune').selectpicker({ liveSearch: true }).ajaxSelectPicker(options);
$('#client_zoneRecherche').selectpicker({ liveSearch: true }).ajaxSelectPicker(options);
