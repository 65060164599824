document.addEventListener('DOMContentLoaded', () => {
  collectionType();
});

function collectionType() {
  if (document.querySelector('.add-another-collection-widget')) {
    // On génère les évenements pour chaque bouton "add-another-collection-widget"
    document.querySelectorAll('.add-another-collection-widget').forEach((addButton) => {
      const idListSelector = addButton.dataset.listSelector;
      const list = document.querySelector(idListSelector);

      addButton.addEventListener('click', () => {
        let counter = list.dataset.widgetCounter || list.children.length;
        let newWidget = list.dataset.prototype;
        const { widgetTags } = list.dataset;
        const widgetClass = list.dataset.widgetClass ? list.dataset.widgetClass.split(',') : [];

        newWidget = newWidget.replace(/__name__/g, counter);
        counter++;
        list.dataset.widgetCounter = counter;

        const newElem = document.createElement(widgetTags);
        if (widgetClass.length > 0) {
          newElem.classList.add(...widgetClass);
        }
        newElem.innerHTML = newWidget;
        list.insertAdjacentHTML('beforeend', newElem.outerHTML);
        // eslint-disable-next-line no-undef
        window.scroll(0, findPos(list));

        if (newElem.querySelector('.select-search')) {
          Array.from(newElem.querySelectorAll('.select-search')).map(
            // eslint-disable-next-line no-undef
            bindSearchSelect
          );
        }
      });

      list.addEventListener('click', (event) => {
        if (event.target && event.target.classList.contains('collection-button-remove')) {
          const row = event.target.parentNode.parentNode.parentNode;
          row.remove();
        }
      });
    });
  }
}
