document.addEventListener('DOMContentLoaded', () => {
  if (
    document.getElementById('partenaire_mandat_programme') ||
    document.getElementById('programme_mandat_partenaire')
  ) {
    const $form = $('#form-mandat');

    if (document.getElementById('partenaire_mandat_programme')) {
      getProgramme($form, $('#partenaire_mandat_programme'));
    }

    $(document).on('change', '#partenaire_mandat_programme', () => {
      getProgramme($form, $('#partenaire_mandat_programme'));
    });

    if (document.getElementById('programme_mandat_partenaire')) {
      getPartenaire($form, $('#programme_mandat_partenaire'));
    }

    $(document).on('change', '#programme_mandat_partenaire', () => {
      getPartenaire($form, $('#programme_mandat_partenaire'));
    });
  }
});

function getProgramme($form, $programme) {
  const data = {};
  data[$programme.attr('name')] = $programme.val();

  $.ajax({
    url: $form.attr('action'),
    type: $form.attr('method'),
    data,
    success(html) {
      $('#partenaire_mandat_nomProgramme').replaceWith($(html).find('#partenaire_mandat_nomProgramme'));

      $('#partenaire_mandat_pourcentage').replaceWith($(html).find('#partenaire_mandat_pourcentage'));
    },
  });
}

function getPartenaire($form, $partenaire) {
  const data = {};
  data[$partenaire.attr('name')] = $partenaire.val();

  $.ajax({
    url: $form.attr('action'),
    type: $form.attr('method'),
    data,
    success(html) {
      $('#programme_mandat_nomSociete').replaceWith($(html).find('#programme_mandat_nomSociete'));

      $('#programme_mandat_typeSociete').replaceWith($(html).find('#programme_mandat_typeSociete'));

      $('#programme_mandat_capital').replaceWith($(html).find('#programme_mandat_capital'));

      $('#programme_mandat_sexePresident').replaceWith($(html).find('#programme_mandat_sexePresident'));

      $('#programme_mandat_president').replaceWith($(html).find('#programme_mandat_president'));

      $('#programme_mandat_adresse').replaceWith($(html).find('#programme_mandat_adresse'));
      $('#programme_mandat_immatriculation').replaceWith($(html).find('#programme_mandat_immatriculation'));

      $('#programme_mandat_numeroCpi').replaceWith($(html).find('#programme_mandat_numeroCpi'));

      $('#programme_mandat_siret').replaceWith($(html).find('#programme_mandat_siret'));

      $('#programme_mandat_pourcentage').replaceWith($(html).find('#programme_mandat_pourcentage'));
    },
  });
}
