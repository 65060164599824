$('#addOption').click(() => {
  $('form').removeClass('d-none');
});

$(() => {
  $('.img-modal').css('cursor', 'zoom-in');
  $('.img-modal').on('click', function () {
    if ($(window).width() >= '720') {
      $('.carousel-body').html(
        `<div id="carousel-${$(this).attr('id')}" class="carousel-item active"> <img class="d-block w-100" src="${$(
          this
        ).attr('src')}" > </div>`
      );
      $('#enlargeImageModal').modal('show');
    }

    $('.carousel-control-prev').click(() => {
      const currentId = $('.carousel-body').children().attr('id');
      let number = currentId.split('-').pop();
      number--;
      if ($(`#loop-${number}`).length === 0) {
        number = $('.loop-visuel img').length;
      }
      $('.carousel-body').html(
        `<div id="carousel-loop-${$(`#loop-${number}`).attr(
          'id'
        )}" class="carousel-item active"> <img class="d-block w-100" src="${$(`#loop-${number}`).attr('src')}" > </div>`
      );
    });

    $('.carousel-control-next').click(() => {
      const currentId = $('.carousel-body').children().attr('id');
      let number = currentId.split('-').pop();
      number++;
      if ($(`#loop-${number}`).length === 0) {
        number = 1;
      }
      $('.carousel-body').html(
        `<div id="carousel-loop-${$(`#loop-${number}`).attr(
          'id'
        )}" class="carousel-item active"> <img class="d-block w-100" src="${$(`#loop-${number}`).attr('src')}" > </div>`
      );
    });
  });
});
