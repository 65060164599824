import 'bootstrap-select';
import 'bootstrap-select/dist/js/i18n/defaults-fr_FR';

document.addEventListener('DOMContentLoaded', () => {
  selectPicker();
});

export default function selectPicker() {
  $('.selectpicker').selectpicker();
}
