import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/autocomplete';
import 'popper.js';
import 'bootstrap';

import 'datatables.net-bs4';
import 'bootstrap-select';
import 'ajax-bootstrap-select';
import './flatpickr';

import './bootstrap-select.config';
import './quill.config';
// Admin
import './admin/ajax_search_communes';
import './admin/dataTables';
import './admin/position';
import './admin/mandat';
import './admin/form';
// Default
import './default/ajax_form_lots';
import './default/datatableLots';
import './default/lotDetails';
import './default/form';
// scss
import '../scss/app.scss';

$(() => {
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover();
  $(':file').on('change', function () {
    const fileName = $(this).val();
    $(this).next('.custom-file-label').html(fileName);
  });

  if (document.getElementById('informationLots')) {
    $('#visuelLots').height($('#informationLots').height() + 35);
  }

  $('#show_hide').click(() => {
    $('#show-div').toggleClass('d-none');
  });

  $('.modifDoubleOption').click(function () {
    $.ajax({
      url: `/ajax/modif/doubleOption/${$(this).attr('data-id')}`,
      success(html) {
        $('#doubleOptionFormAdd').replaceWith($(html).find('#doubleOptionFormAdd'));
        $('#doubleOptionAddModal').modal('show');
      },
    });
  });

  if (document.getElementsByClassName('nav-pills') && window.location.hash.substr(1)) {
    const anchor = window.location.hash;
    $(`.nav-pills  a[href="${anchor.substring(0, anchor.length - 1)}"]`).tab('show');
  }

  $('.doubleOptionAdd').click(function () {
    $.ajax({
      url: `/ajax/add/doubleOption/${$(this).attr('data-id')}`,
      success(html) {
        $('#doubleOptionFormAdd').replaceWith($(html).find('#doubleOptionFormAdd'));
        $('#doubleOptionAddModal').modal('show');
      },
    });
  });
});

export default $;
