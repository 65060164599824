document.addEventListener('DOMContentLoaded', () => {
  form();

  $('#programme_search_annee').change(() => {
    form();
  });
  $('form[name=docu_sign_reservation]').submit(() => {
    docusignLoad();
  });
});

function form() {
  if ($('#programme_search_annee').val() === '') {
    $('#programme_search_trimestre').val(null);
    $('#programme_search_trimestre').attr('disabled', true);
  } else {
    $('#programme_search_trimestre').attr('disabled', false);
  }
  $('.selectpicker').selectpicker('refresh');
}

function docusignLoad() {
  $('#btn-docusign-reservation').prop('disabled', true);

  $('#btn-docusign-reservation').html(`
    <div class="spinner-border spinner-border-sm text-light mr-3" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    Reserver
`);
}
