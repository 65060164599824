document.addEventListener('DOMContentLoaded', () => {
  const languageFr = {
    processing: 'Traitement en cours...',
    search: 'Rechercher&nbsp;:',
    lengthMenu: 'Afficher _MENU_ &eacute;l&eacute;ments',
    info: "Affichage de l'&eacute;lement _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
    infoEmpty: "Affichage de l'&eacute;lement 0 &agrave; 0 sur 0 &eacute;l&eacute;ments",
    infoFiltered: '(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)',
    infoPostFix: '',
    loadingRecords: 'Chargement en cours...',
    zeroRecords: 'Aucun &eacute;l&eacute;ment &agrave; afficher',
    emptyTable: 'Aucune donnée disponible dans le tableau',
    paginate: {
      first: 'Premier',
      previous: 'Pr&eacute;c&eacute;dent',
      next: 'Suivant',
      last: 'Dernier',
    },
    aria: {
      sortAscending: ': activer pour trier la colonne par ordre croissant',
      sortDescending: ': activer pour trier la colonne par ordre décroissant',
    },
  };

  $('#dataTable').DataTable({
    language: languageFr,
    paging: false,
  });

  $('#dataTablePagination').DataTable({
    language: languageFr,
    pageLength: 100,
    order: [[0, 'desc']],
  });

  $('#dataTableP2').DataTable({
    language: languageFr,
    paging: false,
  });

  $('#dataTableP3').DataTable({
    language: languageFr,
    paging: false,
  });

  $('.dataTable2').DataTable({
    paging: false,
    info: false,
    search: false,
    language: languageFr,
  });

  $('.dataTableProgramme').DataTable({
    paging: false,
    info: false,
    search: false,
    language: languageFr,
    order: [[1, 'asc']],
  });

  const dataTableSearch = $('#dataTableSearch').DataTable({
    paging: false,
    info: false,
    language: languageFr,
  });

  $('#dataTableSearchDesc').DataTable({
    paging: false,
    info: false,
    order: [[0, 'desc']],
  });

  $('#dataTableProspect').DataTable({
    language: languageFr,
    paging: false,
    order: [[5, 'desc']],
  });

  $('#dataTableSearch_filter').css('display', 'none');
  $('#lot_option_partenaire, #lot_option_client,#lot_option_statut').change(() => {
    dataTableSearch.draw();
  });
});
